import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { OrenFeatureShellModule } from '@oren/feature-shell';
import { environment } from '@oren/util-environment';
import { AppComponent } from './app.component';
import { COMPONENT_MAPPING } from './cms-config/cms-component-mapping.config';
import { cmsDataUrl } from './cms-config/cms-data-url';
import {
  gaTagsConfig,
  googleTagGlobalProps,
} from './ga-tags-config/ga-tags-config';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    OrenFeatureShellModule.forRoot({
      componentMapping: COMPONENT_MAPPING,
      gaTagsConfig,
      cmsDataUrl,
      googleTagGlobalProps,
    }),
  ],
  providers: [
    { provide: 'isDevelopmentEnvironment', useValue: !environment.production }, // to enable debug logger
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
